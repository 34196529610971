<template>
  <div>
    <q-form ref="editForm">
      <!-- 업무일지 기본정보 -->
      <c-card title="업무일지 기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- [S]결재관련 버튼 -->
            <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
            <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
            <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
            <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
            <c-appr-btn 
              ref="appr-btn"
              name="safety-check-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveDataAppr"
              @callbackApprAction="approvalCallback"
              @requestAfterAction="getDetail"
            />
            <!-- 삭제 -->
            <c-btn 
              v-if="editable && !disabled && isOld" 
              label="LBLREMOVE" 
              icon="delete_forever" 
              @btnClicked="removeData" />
            <!-- 저장 -->
            <c-btn
              v-if="editable && !disabled && !safetyCheck.sysApprovalRequestId"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="safetyCheck"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              required
              :disabled="disabled || isOld"
              :editable="editable"
              type="none"
              name="plantCd"
              v-model="safetyCheck.plantCd"
              @datachange="datachange('A')">
            </c-plant>
          </div>
          <!-- 구분 -->
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              required
              type="edit"
              :disabled="disabled || isOld"
              itemText="codeName"
              itemValue="code"
              name="checkTypeCd"
              label="구분"
              codeGroupCd="SOP_SAFETY_TYPE_CD"
              v-model="safetyCheck.checkTypeCd"
              @datachange="datachange('A')"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 점검년월 -->
            <c-datepicker
              required
              :disabled="disabled"
              :editable="editable"
              label="LBL0002200"
              type="month"
              name="checkDate"
              v-model="safetyCheck.checkDate"
              @datachange="datachange('B')">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 점검부서 -->
            <c-dept
              required
              :disabled="disabled"
              :editable="editable"
              label="LBL0002201"
              name="checkDeptCd"
              v-model="safetyCheck.checkDeptCd"
              @setDeptName="setDeptName">
            </c-dept>
          </div>
        </template>
      </c-card>
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        :height="tabHeight"
        v-model="tab"
        align="left"
        @tabClick="tabClick"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :safetyCheck="safetyCheck"
            :grid.sync="grid"
            :imprTabInfo.sync="imprTabInfo"
            :isOld="isOld"
            :contentHeight="contentHeight"
            :disabled="disabled"
            @getDetail="getDetail"
            @setKey="setKey"
            @save="saveData2"
          />
        </template>
      </c-tab>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [],
        height: 'auto',
      },
      safetyCheck: {
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkTypeCd: null, // 관리감독자/안전관리자
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        safetyCheckResultModelList: [], // sheet
        safetyCheckImproveModelList: [], // 개선 list
        safetyCheckUserModelList: [],
      },
      editable: true,
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isApproval: false,
      mappingType: 'POST',
      detailUrl: '',
      resultItemUrl: '',
      holidayUrl: '',
      holidayList: [],
      today: '',
      tab: 'safetyCheckResult',
      tabItems: [
        // 업무일지 결과
        { name: 'safetyCheckResult', icon: 'list', label: '업무일지 결과', component: () => import(`${'./safetyCheckResult.vue'}`) },
        // 개선관리
        { name: 'safetyCheckImpr', icon: 'construction', label: 'LBL0000331', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`), disabled: true },
      ],
      research: {
        impr: '',
        item: '',
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 156);
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.safetyCheck.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.safetyCheck.approvalStatusCd, 
        apprEditable: this.safetyCheck.checkStatusCd && this.safetyCheck.checkStatusCd !== 'MCSC000015', // 결재버튼 활성화 기준
        param: this.safetyCheck, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000008', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          safetyCheckId: this.safetyCheck.safetyCheckId,
          isApprContent: true
        },
        approvalRequestName: '업무일지 [' + this.safetyCheck.safetyCheckName + ']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.safetyCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    isOld() {
      return Boolean(this.popupParam.safetyCheckId);
    },
    disabled() {
      return this.safetyCheck.checkStatusCd === 'MCSC000015'
        || this.safetyCheck.approvalStatusCd === 'ASC0000001'
    },
    imprTabInfo() {
      return {
        key: this.popupParam.safetyCheckId, // 
        ibmTaskTypeCd: 'ITT0000075',
        ibmTaskUnderTypeCd: 'ITTU000125',
        requestContents: this.safetyCheck.safetyCheckName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: selectConfig.sop.safetycheck.improve.get.url,
          param: {
            safetyCheckId: this.popupParam.safetyCheckId,
            imprFlag: true,
          },
          title: 'LBL0002209', // 점검항목별 개선목록
          merge: [
            { index: 0, colName: 'safetyCheckResultId' },
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              // 점검항목
              label: 'LBL0002210',
              align: 'left',
              style: 'width:40%',
              sortable: false,
              fix: true,
            },
            {
              name: 'dayStr',
              field: 'dayStr',
              label: '일',
              align: 'center',
              style: 'width:10%',
              sortable: false,
              fix: true,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: 'LBL0001424', // 항목외 개선 목록
        },
      }
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 240);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.popupParam.safetyCheckId) {
        this.$set(this.tabItems[1], 'disabled', false)
      }
      // url setting
      this.detailUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.updateUrl = transactionConfig.sop.safetycheck.plan.update.url;
      this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      this.deleteUrl = transactionConfig.sop.safetycheck.plan.delete.url;
      this.resultItemUrl = selectConfig.sop.safetycheck.result.newItem.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url; // 음력 공휴일구하기
      // code setting
      this.today = this.$comm.getToday();
      // list setting
      this.getDetail()
      
    },
    getDetail() {
      if (this.popupParam.safetyCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.safetyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.safetyCheck, _result.data);
          this.setColumns();
        },);
      } else {
        this.datachange('A');
      }
    },
    setKey(data) {
      this.popupParam.safetyCheckId = data
      this.getDetail();
    },
    datachange(type) {
      if (type === 'A' && this.safetyCheck.plantCd && this.safetyCheck.checkTypeCd) {
        /**
         * 사업장에 따라 점검항목을 변경
         * 
         * 사업장은 `선택`이 될 수 없게 처리
         */
        this.$http.url = this.resultItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.safetyCheck.plantCd,
          checkTypeCd: this.safetyCheck.checkTypeCd,
        };
        this.$http.request((_result) => {
          this.safetyCheck.safetyCheckResultModelList = _result.data;
          this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
            _item.safetyCheckResultId = uid()
          })
          this.setColumns();
        },);
      } else if (type === 'B' && this.safetyCheck.checkDate) {
        // this.grid.data = [];
        this.setColumns();
      }
    },
    setColumns() {
      if (!this.safetyCheck.checkDate) return;
      let _columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          // 점검항목
          label: 'LBL0002210',
          align: 'left',
          style: 'width:300px',
          sortable: false,
          fix: true,
        },
      ]
      let year = this.safetyCheck.checkDate.substring(0, 4);
      let month = this.safetyCheck.checkDate.substring(5);

      let days = [];
      this.$http.url = this.$format(this.holidayUrl, year);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.holidayList = _result.data

        let yearmonth = `${year}-${this.$_.padStart(month, 2, '0')}`;
        let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();

        for (let idx = 1; idx <= yearmonthDays; idx++) {
          let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
          days.push({
            name: 'day' + idx + 'Name',
            field: 'day' + idx + 'Name',
            key: 'day' + idx,
            label: idx,
            align: 'center',
            setHeader: true,
            style: 'width:35px',
            headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
            tdColor: isHoliday ? '#D9D9D9' : null,
            headerType: 'link',
            sortable: false,
          })
        }
        this.grid.columns = this.$_.concat(_columns, days);
      },);

      this.safetyCheck.safetyCheckName = year + '년  ' + month + '월 업무일지'
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else if (this.$_.findIndex(this.holidayList, { holiday: day }) > -1) {
        return true;
      } else {
        return false;
      }
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.safetyCheck.regUserId = this.$store.getters.user.userId;
              this.safetyCheck.chgUserId = this.$store.getters.user.userId;

              if (this.mappingType === 'POST') {
                this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
                  _item.editFlag = 'C';
                })
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveData2() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          this.safetyCheck.regUserId = this.$store.getters.user.userId;
          this.safetyCheck.chgUserId = this.$store.getters.user.userId;

          if (this.mappingType === 'POST') {
            this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
              _item.editFlag = 'C';
            })
          }

          this.isSave = !this.isSave;
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.safetyCheckId = result.data
      if (this.mappingType === 'POST') {
        this.$set(this.tabItems[1], 'disabled', false)
      }
      this.getDetail();
    },
    saveDataAppr() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.safetyCheck.regUserId = this.$store.getters.user.userId;
              this.safetyCheck.chgUserId = this.$store.getters.user.userId;
              let promises = [
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          })
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.safetyCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    setDeptName(deptName) {
      this.safetyCheck.checkDeptName = deptName;
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    },
  }
};
</script>
